import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid, List, ListItem, ListItemText,Box } from '@mui/material';
import { useContext } from 'react';
import { useAuth } from "../hooks/useAuth";
import { getData } from '../utils/api';

const UserInfo = () => {
    const user = useAuth().userData;
    const [userInfo, setUserInfo] = React.useState({});

    const API_URL = process.env.REACT_APP_API_URL;
    
    React.useEffect(() => {
        const fetchUser = async () => {
          try {
            const response = await getData(`${API_URL}/users/${user.user_id}`, user.auth_token);
            if (response) {
                setUserInfo(response);
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          } 
        };
        fetchUser();
      }, []);

  return (
    <Grid item xs={12}>
    
        <Card sx={{ maxWidth: 345, margin: 'auto', mt: 3, display: 'flex',}}>
        <CardContent>
            <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Avatar alt={userInfo.name} src={''} sx={{ width: 56, height: 56 }} />
            </Grid>
            <Grid item xs>
                <Typography variant="h5" component="div">
                {userInfo?.first_name?.replace("-", " ")}
                </Typography>
            </Grid>
            </Grid>
            <List>
            <ListItem>
                <ListItemText primary="Email" secondary={userInfo.username} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Phone Number" secondary={userInfo.last_name} />
            </ListItem>
            </List>
        </CardContent>
        </Card>
    
    </Grid>
  );
};

export default UserInfo;