import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { utcToLocal } from '../utils/datetimefunctions';
import { formatCurrency } from '../utils/currency';
import { postDataToken } from '../utils/api';
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";


export default function CourtCard({ court, slot_length, onActivateAlert }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedHour, setSelectedHour] = useState(null);

  const minPrice = court.slots.reduce((min, slot) => {
    return slot.price < min ? slot.price : min;
  }, court.slots[0].price);

  const minPriceFormatted = formatCurrency(minPrice, 'es-CL', 'currency','CLP',0,0);
  const distanceMessage = court.distance ? `A ${court.distance} de tu dirección seleccionada.` : '';

  const user = useAuth().userData;
  const API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
 
  const handleClick = async () => {
    const body = {
      court: court.court_id,
      start_dttm: selectedHour.start_time,
      end_dttm: selectedHour.end_time,
      is_cancelled: false,
    }
    const reservation = await postDataToken(`${API_URL}/reservations/`, user.auth_token, body);
    if (reservation.id) {
      const reserveData = {
        reservation: reservation.id,
        date: utcToLocal(reservation.start_dttm,'date2'),
        time: utcToLocal(reservation.start_dttm,'time'),
        minutes: slot_length,
        court: court.court_name,
        club: court.club_name,
        address: court.club_address,
        price: selectedHour.price,
        bank: reservation.club_bank,
      }
      navigate('/confirmation', { state: { reserveData } });
    } else {
      onActivateAlert(true);
    }
  }

  return (
    <Card >
      <CardContent>
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: '600' }}
        >
          {court.club_name + ": " + court.court_name}
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
          {`Reservas de ${slot_length} minutos desde ${minPriceFormatted}. ${distanceMessage}`}
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
          Horarios disponibles:
        </Typography>
        {court.slots.map((slot) => (
          <Button
            key={slot.start_time}
            variant={selectedHour === slot ? 'contained' : 'outlined'}
            onClick={() => setSelectedHour(slot)}
            sx={{ mr: 1, mb: 1 }}
          >
            {utcToLocal(slot.start_time,'time')}
          </Button>
        ))}
        
        {selectedHour ?
        <Button
          variant="contained"
          size="small"
          color="primary"
          endIcon={<ChevronRightRoundedIcon />}
          fullWidth={isSmallScreen}
          sx={{ mr: 1, mb: 1 }}
          onClick={handleClick}
          disabled={true}
        >
          Reservar por {formatCurrency(selectedHour.price, 'es-CL', 'currency','CLP',0,0)}
        </Button>
        : null}
      </CardContent>
    </Card>
  );
}