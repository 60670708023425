import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';

import { Link } from 'react-router-dom';



export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  return (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
            <BottomNavigationAction 
                label="Inicio" 
                icon={<HomeIcon />} 
                component={Link}
                to="/"
            />
            <BottomNavigationAction 
                label="Mis Reservas" 
                icon={<CalendarTodayIcon/>} 
                component={Link}
                to="/reservations"
            />
            <BottomNavigationAction 
                label="Mi Perfil" 
                icon={<AccountCircleIcon />} 
                component={Link}
                to="/profile"
            />
        </BottomNavigation>
      </Paper>
  );
}
