import { Routes, Route, createBrowserRouter, createRoutesFromElements, defer } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
//import { Secret } from "./pages/Secret";

import ErrorPage from './pages/error-page';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Progress from './pages/Progress';
import Reservations from './pages/Reservations';
import ConfirmationReserve from './pages/ConfirmationReserve';
import Reserve from './pages/Reserve';
import HomeInside from './pages/HomeInside';

import "./App.css";
import { ProtectedRoute } from "./Components/ProtectedRoute";
//import { AuthProvider } from "./hooks/useAuth"; 
import { AuthLayout } from "./layouts/AuthLayout";

import PassRecovery from "./pages/PassRecovery";
import Activate from "./pages/Activate";


// ideally this would be an API call to server to get logged in user data
const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("userData");
      resolve(user);
    }, 3000)
  );


// Add Error Page as Route prop: errorElement = {<ErrorPage />}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData()})}
            errorElement = {<ErrorPage />}      
        >   
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/recovery/:uid/:token"
                loader={({ params }) => {
                    return {params}
                  }}
                element={<PassRecovery />} 
            />
            <Route path="/activate/:uid/:token"
                loader={({ params }) => {
                    return {params}
                  }}
                element={<Activate />} 
            />
            <Route element={<Home />}>
                <Route path="/" element={<HomeInside />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="reserve" element={<Reserve/>} />
                    <Route path="confirmation" element={<ConfirmationReserve />} />
                    <Route path="reservations" element={<Reservations />} />
                    <Route path="profile" element={<Profile />} />
                </Route>
            </Route>
        </Route>
    )
);

