import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from "./App";
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById("root")).render(
  // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} >
     <React.StrictMode>
       <RouterProvider router={router} />
     </React.StrictMode>
   //</GoogleOAuthProvider>
 );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
