import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Autocomplete, Paper, Stack, MenuItem, Button, Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import GoogleMaps from '../Components/GoogleMaps';
import CourtCard from '../Components/CourtCard';
import { getDataFilters, getDataFiltersWithoutToken } from '../utils/api';
import { useAuth } from '../hooks/useAuth';
import { addDaysFromDate } from '../utils/datetimefunctions';
import SnackbarAlert from '../Components/SnackbarAlert';
import CircularProgress from '@mui/material/CircularProgress';

function getDayLabel(dayIndex) {
  const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  return daysOfWeek[dayIndex];
}

function generateDaysList() {
  const daysList = [
    { label: 'Hoy', value: 0},
    { label: 'Mañana', value: 1},
  ];
  const startValue = 2;  // Comienza en el valor 2 para pasado mañana

  for (let i = 0; i < 5; i++) {
      // Crear una nueva fecha basada en el día actual más (i + 2) días
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + (i + 2));
      
      // Obtener el día de la semana como un índice
      const dayOfWeek = futureDate.getDay();
      
      // Crear el objeto con label y value
      daysList.push({
          label: getDayLabel(dayOfWeek) + " " + futureDate.getDate(),
          value: startValue + i
      });
  }

  return daysList;
}


export default function Reserve() {
  const [when, setWhen] = React.useState(0);
  const nextHourString = (new Date().getHours() + 2).toString();
  const [whenTime, setWhenTime] = React.useState(nextHourString.padStart(2, '0') + ":00");
  const [location, setLocation] = React.useState(null);

  const [minutes, setMinutes] = React.useState(90);

  const [loading, setLoading] = React.useState(false); 
  
  const [availableCourts, setAvailableCourts] = React.useState([]);

  const [activateAlert, setActivateAlert] = React.useState(false);
  
  const userInfo = useAuth().userData;
  const API_URL = process.env.REACT_APP_API_URL;

  
  useEffect(() => {
    const savedAddress = JSON.parse(localStorage.getItem('selectedAddress'));
    if (savedAddress) {
      setLocation(savedAddress);
    }
  }, []);
  

  const handleSearch = async () => {
    setLoading(true);
    
    setAvailableCourts([]);
    
    // Sport filter missing until we have more sports
    const filterData = {
      slot_day: addDaysFromDate(new Date(), when),
      slot_length: minutes,
      slot_time: whenTime,
      user_address: location?.description
    }

    const courts = await getDataFilters(`${API_URL}/available-courts/`,userInfo.auth_token, filterData)
    setAvailableCourts(courts);
    setLoading(false);
    localStorage.setItem('selectedAddress', JSON.stringify(location));
  }

  const handleClose = () => {
    setActivateAlert(false);
  };

  useEffect(() => {
    setAvailableCourts([]);
  }, [when, whenTime, location, minutes]);

    return (
      <Grid container spacing={3}>

        <Grid item xs={12} md={12} lg={12} sx={{marginBottom: 4}}>

        <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                <Grid 
                    container 
                    spacing={2} 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0 }}
                >
                  <Grid item xs={6} sm={6} md={2}>
                    <TextField 
                      label="Deporte" 
                      name="sport"
                      select
                      value={"Padel"}
                      fullWidth
                    >
                      <MenuItem value="Padel">Padel</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={6} md={2}>
                    <TextField label="Cuándo" name="when"
                        select
                        value={when}
                        onChange={(e) => setWhen(e.target.value)}
                        fullWidth
                    >
                      {generateDaysList().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={6} md={2}>
                    <TextField label="Hora" name="time"
                        value={whenTime}
                        type="time"
                        onChange={(e) => setWhenTime(e.target.value)}
                        fullWidth
                    />
                  </Grid>

                <Grid item xs={6} sm={6} md={2}>
                    <TextField label="Cuánto quiero jugar" name="howlong"
                        select
                        value={minutes}
                        onChange={(e) => setMinutes(e.target.value)}
                        fullWidth
                    >
                      <MenuItem value={90}>1 hora y media</MenuItem>
                      <MenuItem value={60}>1 hora</MenuItem>
                      <MenuItem value={120}>2 horas</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <GoogleMaps value={location} setValue={setLocation}/>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <Button 
                      size='small'
                      variant="contained" color="primary" onClick={handleSearch}>
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'} {/* Show CircularProgress when loading */}
                    </Button>
                  </Grid>
                </Grid> 
            </Paper>
            <Grid container spacing={2}>
            {availableCourts?.map((court) => (
              <Grid item xs={12} key={'court'+court.court_id}>
              <CourtCard  
                court={court} 
                slot_length={minutes} 
                location={location}
                onActivateAlert={setActivateAlert}
              />
              </Grid>
            ))  
            }
            </Grid>
            
            <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity="error"  // Puedes cambiar a "error", "warning", "info"
            message="Error al crear la reserva. Vuelva a intentarlo."
            ms={6000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />   

        </Grid>
      </Grid>
    );
  }